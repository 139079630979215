import Component from '../../component';
import Swiper from 'swiper';

export default class HeroComponent extends Component {

    private readonly _slider: HTMLElement;
    private _swiper: Swiper;

    constructor(element: HTMLElement) {
        super(element);

        console.log('HeroComponent', 'element', element);

        this._slider = element.querySelector('.c-hero__slider');

        this._initSwiper();
    }

    private _initSwiper() {

        this._swiper = new Swiper(this._slider, {
            autoplay: {
                delay: 5000,
            },
            loop: true,
            navigation: {
                nextEl: '.swiper-button-next',
                prevEl: '.swiper-button-prev',
            },
            parallax: true,
            speed: 1000
        });

    }
}
