// @ts-ignore
import * as Bowser from 'bowser';
import AOS from 'aos';
import 'cookieconsent';

const GOOGLE_ANALYTICS_PROPERTY = 'UA-151944201-1';
const GOOGLE_ANALYTICS_URL = `https://www.googletagmanager.com/gtag/js?id=${GOOGLE_ANALYTICS_PROPERTY}`;

export default class PageController {

    static readonly COOKIE_CONSENT_COOKIE_NAME = 'cookieconsent_status';
    static readonly BODY_PRELOAD_MODIFIER_CLASS = '--preload';
    static readonly BODY_BROWSER_BASE_MODIFIER_CLASS = '--browser-';

    private readonly _body: HTMLElement;

    // @ts-ignore
    private _browserParser: Bowser.Parser.Parser;

    private _onLoadListeners: (() => void)[] = [];

    constructor() {

        this._body = document.body;

        this._initListeners();
        this._determineBrowser();
        this._initAOS();
    }

    public addOnLoadListener(listener: () => void) {

        if ('function' !== typeof listener) {
            return;
        }

        this._onLoadListeners.push(listener);
    }

    public removeOnLoadListener(listener: () => void) {

        if ('function' !== typeof listener) {
            return;
        }

        const listenerIndex = this._onLoadListeners.indexOf(listener);

        if (-1 === listenerIndex) {
            return;
        }

        this._onLoadListeners.splice(listenerIndex, 1);
    }

    private _initListeners() {
        window.addEventListener('load', this._onLoad.bind(this));
    }

    private _onLoad(e: Event) {
        this._body.classList.remove(PageController.BODY_PRELOAD_MODIFIER_CLASS);

        this._onLoadListeners.forEach(listener => listener());

        // @ts-ignore
        if ('allow' === window.cookieconsent.utils.getCookie(PageController.COOKIE_CONSENT_COOKIE_NAME)) {
            this._loadGoogleAnalytics();
        }

        this._initCookieConsent();
        AOS.refresh();
    }

    private _initCookieConsent() {

        // @ts-ignore
        const cookieConsent = new window.cookieconsent.initialise({
            type: 'opt-in',
            revokable: true,
            palette: {
                popup: {
                    background: '#393939'
                },
                button: {
                    background: '#fab913'
                }
            },
            content: {
                header: 'Verwendung von Cookies',
                message: 'Cookies helfen uns bei der Bereitstellung unserer Inhalte und Dienste. Durch die weitere Nutzung der Webseite stimmen Sie der Verwendung von Cookies zu.',
                allow: 'Cookies erlauben',
                deny: 'Cookies ablehnen',
                link: 'Weitere Informationen',
                href: 'https://www.scheiben-expert.de/datenschutz/',
                target: '_blank',
                policy: 'Cookie-Richtline'
            },
            onStatusChange: this._onCookieConsentStatusChange.bind(this)
        });
        console.log('PageController', '_initCookieConsent', cookieConsent);

    }

    private _onCookieConsentStatusChange(status: any) {
        console.log('PageController', '_onCookieConsentStatusChange', status);

        if ('allow' === status) {
            this._loadGoogleAnalytics();
        } else if ('deny' === status) {
            window.location.reload();
        }
    }

    private _loadGoogleAnalytics() {

        if (!document.querySelectorAll(`[src="${GOOGLE_ANALYTICS_URL}"]`).length) {
            // @ts-ignore
            document.body.appendChild(Object.assign(
                document.createElement('script'), {
                    type: 'text/javascript',
                    src: GOOGLE_ANALYTICS_URL,
                    onload: this._initGoogleAnalytics.bind(this)
                }));
        } else {
            this._initGoogleAnalytics();
        }

    }

    private _initGoogleAnalytics() {

        // @ts-ignore
        window.dataLayer = window.dataLayer || [];

        // @ts-ignore
        window.gtag = () => {
            // @ts-ignore
            window.dataLayer.push(arguments);
        };

        // @ts-ignore
        window.gtag('js', new Date());

        // @ts-ignore
        window.gtag('config', GOOGLE_ANALYTICS_PROPERTY, {
            'anonymize_ip': true,
            'allow_display_features': false,
            'link_attribution': false
        });

    }

    private _determineBrowser() {
        console.log('PageController', '_determineBrowser');

        this._browserParser = Bowser.getParser(window.navigator.userAgent);
        console.log('PageController', '_browserParser', this._browserParser);

        const browserName = this._browserParser.getBrowserName();
        console.log('PageController', 'browserName', browserName);

        const modifiedBrowserName = browserName.toLowerCase().replace(/\s/, '-');
        console.log('PageController', 'modifiedBrowserName', modifiedBrowserName);

        this._body.classList.add(`${PageController.BODY_BROWSER_BASE_MODIFIER_CLASS}${modifiedBrowserName}`);

    }

    private _initAOS() {
        console.log('PageController', '_initAOS');

        if (this._browserParser.is('ie')) {
            console.log('PageController', '_initAOS', 'disable AOS on IE');
            return;
        }

        AOS.init({
            disable: 'mobile'
        });

    }
}
