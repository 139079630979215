import Component from '../../component';
import { GeneralUtils } from '../../utils/general.utils';
import NavItemComponent from './nav-item.component';
import { SlideableHTMLElement } from '../../models/slideable-html-element.model';
import { FadeableHTMLElement } from '../../models/fadeable-html-element.model';

export default class MobileMenuComponent extends Component {

    static readonly MENU_OPEN_BODY_MODIFIER_CLASS = '--mobile-menu-open';
    static readonly DOM_SLIDER_HIDDEN_CLASS = 'DOM-slider-hidden';

    protected readonly _element: SlideableHTMLElement;
    private readonly _toggle: HTMLElement;
    private readonly _backdrop: FadeableHTMLElement;
    private readonly _body: HTMLElement;

    private readonly _navItemComponents: NavItemComponent[] = [];

    private _wasMobile: boolean;
    private _isMobile: boolean;
    private _isOpen: boolean = false;
    private _isSliding: boolean;

    constructor(element: SlideableHTMLElement, toggle: HTMLElement, backdrop: FadeableHTMLElement) {
        super(element);

        this._toggle = toggle;
        this._backdrop = backdrop;
        this._body = document.body;

        this._initNavItemComponents();
        this._detectMobile();
        this._initListeners();
    }

    private _initNavItemComponents() {

        const navItemElements = this._element.querySelectorAll('ul.c-nav__list > li.list__item');

        // @ts-ignore
        navItemElements.forEach((element, index) => {
            const navItem = new NavItemComponent(element as HTMLElement, index);
            navItem.on('openSub', this._onNavItemOpenSubFactory(navItem));
            this._navItemComponents.push(navItem);
        });

    }

    private _onNavItemOpenSubFactory(currentNavItem: NavItemComponent) {
        return () => {
            this._navItemComponents.forEach((navItem: NavItemComponent) => {
                if (currentNavItem.index !== navItem.index) {
                    navItem.closeSub();
                }
            });
        };
    }

    private _detectMobile() {
        this._wasMobile = this._isMobile;
        this._isMobile = window.innerWidth < GeneralUtils.MOBILE_BREAKPOINT;

        if (!this._isMobile) {
            this._element.classList.remove(MobileMenuComponent.DOM_SLIDER_HIDDEN_CLASS);
        }
    }

    private _initListeners() {
        this._toggle.addEventListener('click', this._onToggleClick.bind(this));
        this._backdrop.addEventListener('click', this._onBackdropClick.bind(this));
        window.addEventListener('resize', this._onWindowResize.bind(this), { passive: true });
    }

    private _onWindowResize(e: any) {
        this._detectMobile();
    }

    private _onToggleClick(e: MouseEvent) {
        console.log('MobileMenuComponent', '_onToggleClick');

        e.preventDefault();

        if (this._isSliding) {
            return;
        }

        this._isOpen = !this._isOpen;

        if (this._isOpen) {
            this._open();
        } else {
            this._close();
        }

    }

    private _open() {
        console.log('MobileMenuComponent', '_open');

        this._body.classList.add(MobileMenuComponent.MENU_OPEN_BODY_MODIFIER_CLASS);

        this._isSliding = true;

        this._backdrop.fadeIn();

        this._element.slideDown().then(() => {
            console.log('MobileMenuComponent', '_open', 'done');
            this._isSliding = false;
        });
    }

    private _close() {
        console.log('MobileMenuComponent', '_close');

        this._isSliding = true;

        this._backdrop.fadeOut();

        this._element.slideUp().then(() => {
            console.log('MobileMenuComponent', '_close', 'done');
            this._isSliding = false;
            this._body.classList.remove(MobileMenuComponent.MENU_OPEN_BODY_MODIFIER_CLASS);
        });
    }

    private _onBackdropClick(e: MouseEvent) {
        console.log('MobileMenuComponent', '_onToggleClick');

        e.preventDefault();

        this._isOpen = false;
        this._close();
    }

}
