import '../scss/styles.scss';

import 'dom-slider';
import 'dom-fader';

import PageController from './controller/page.controller';
import HeroController from './components/hero/hero.controller';
import ContactMapController from './components/contact-map/contact-map.controller';
import MobileMenuComponent from './components/mobile-menu/mobile-menu.component';
import ContactFormController from './components/contact-form/contact-form.controller';
import { SlideableHTMLElement } from './models/slideable-html-element.model';
import { FadeableHTMLElement } from './models/fadeable-html-element.model';

const pageController = new PageController();

pageController.addOnLoadListener(() => {

    const mobileMenuComponent = new MobileMenuComponent(
        document.getElementById('main-nav') as SlideableHTMLElement,
        document.getElementById('toggle-mobile-menu'),
        document.getElementById('mobile-menu-backdrop') as FadeableHTMLElement
    );
    const heroController = new HeroController();
    const contactMapController = new ContactMapController();
    const contactFormContoller = new ContactFormController();

});
