// @ts-ignore
import { default as EventEmitter } from 'eventemitter3';

export interface IAbstractComponentInstance<T extends Component = Component> {
    new(element: HTMLElement): T;
}

export default class Component {

    protected readonly _element: HTMLElement;

    private readonly _eventEmitter: EventEmitter;

    constructor(element: HTMLElement) {
        this._element = element;

        if (!this._element) {
            throw new Error('Element not found.');
        }

        this._eventEmitter = new EventEmitter();
    }

    get element(): HTMLElement {
        return this._element;
    }

    on(event: string, listener: (...args: any[]) => void, context?: any): EventEmitter {
        return this._eventEmitter.on(event, listener, context);
    }

    off(event: string, listener: (...args: any[]) => void, context?: any, once?: boolean): EventEmitter {
        return this._eventEmitter.off(event, listener, context, once);
    }

    protected _emit(event: string, ...args: any[]): boolean {
        // @ts-ignore
        return this._eventEmitter.emit(event, ...args);
    }

}
