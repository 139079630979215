import Component from '../../component';
import { SlideableHTMLElement } from '../../models/slideable-html-element.model';
import { GeneralUtils } from '../../utils/general.utils';
import MobileMenuComponent from './mobile-menu.component';

export default class NavItemComponent extends Component {

    static readonly ACTIVE_MODIFIER_CLASS = 'list__item--active';
    static readonly HAS_SUB_MODIFIER_CLASS = 'list__item--has-sub';
    static readonly SUB_OPEN_MODIFIER_CLASS = 'list__item--sub-open';
    static readonly ITEM_INNER_SELECTOR = '.item__inner';
    static readonly SUB_NAV_LIST_SELECTOR = 'ul.c-nav__list';
    static readonly SUB_NAV_LIST_DOM_SLIDER_HIDDEN_CLASS = 'DOM-slider-hidden';

    private readonly _index: number;

    private _itemInner: HTMLElement;
    private _subNavList: SlideableHTMLElement;

    private _wasMobile: boolean;
    private _isMobile: boolean;
    private _isActive: boolean;
    private _hasSub: boolean;
    private _isSubOpen: boolean;
    private _isSubSliding: boolean;

    constructor(element: HTMLElement, index: number) {
        super(element);

        this._index = index;

        for (let i = 0; i < element.children.length; i++) {

            if (!this._itemInner && element.children[i].matches(NavItemComponent.ITEM_INNER_SELECTOR)) {
                this._itemInner = element.children[i] as HTMLElement;
            }

            if (!this._subNavList && element.children[i].matches(NavItemComponent.SUB_NAV_LIST_SELECTOR)) {
                this._subNavList = element.children[i] as SlideableHTMLElement;
            }

        }

        this._isActive = element.classList.contains(NavItemComponent.ACTIVE_MODIFIER_CLASS);
        this._hasSub = element.classList.contains(NavItemComponent.HAS_SUB_MODIFIER_CLASS);
        this._isSubOpen = element.classList.contains(NavItemComponent.SUB_OPEN_MODIFIER_CLASS);

        this._detectMobile();
        this._initListeners();
    }

    get index(): number {
        return this._index;
    }

    public toggleSub() {

        if (this._isSubOpen) {
            this.closeSub();
        } else {
            this.openSub();
        }

    }

    public openSub() {
        console.log('NavItemComponent', this._index, 'openSub');

        if (!this._hasSub || this._isSubOpen || this._isSubSliding) {
            return;
        }

        this._isSubOpen = true;

        this._element.classList.add(NavItemComponent.SUB_OPEN_MODIFIER_CLASS);

        this._isSubSliding = true;

        this._emit('openSub');

        this._subNavList.slideDown().then(() => {
            console.log('NavItemComponent', this._index, 'openSub', 'done');
            this._isSubSliding = false;
        });
    }

    public closeSub() {
        console.log('NavItemComponent', this._index, 'closeSub');

        if (!this._hasSub || !this._isSubOpen || this._isSubSliding) {
            return;
        }

        this._isSubOpen = false;

        this._isSubSliding = true;

        this._emit('closeSub');

        this._subNavList.slideUp().then(() => {
            console.log('NavItemComponent', this._index, 'closeSub', 'done');
            this._isSubSliding = false;
            this._element.classList.remove(NavItemComponent.SUB_OPEN_MODIFIER_CLASS);
        });
    }

    private _initListeners() {

        window.addEventListener('resize', this._onWindowResize.bind(this), { passive: true });

        if (this._hasSub && this._itemInner) {
            this._itemInner.addEventListener('click', this._onToggleSubClick.bind(this));
        }

    }

    private _onWindowResize(e: any) {
        this._detectMobile();
    }

    private _detectMobile() {
        this._wasMobile = this._isMobile;
        this._isMobile = window.innerWidth < GeneralUtils.MOBILE_BREAKPOINT;

        if (!this._isMobile) {
            if (this._subNavList) {
                this._subNavList.classList.remove(NavItemComponent.SUB_NAV_LIST_DOM_SLIDER_HIDDEN_CLASS);
            }
        }
    }

    private _onToggleSubClick(e: MouseEvent) {
        console.log('NavItemComponent', this._index, '_onToggleSubClick');

        e.preventDefault();

        if (window.innerWidth < GeneralUtils.MOBILE_BREAKPOINT) {
            this.toggleSub();
        }

    }

}
