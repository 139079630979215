import Component from '../../component';
import { GeneralUtils } from '../../utils/general.utils';
import { SlideableHTMLElement } from '../../models/slideable-html-element.model';
import { FadeableHTMLElement } from '../../models/fadeable-html-element.model';

export enum ContactFormStatus {
    INITIAL = 'initial',
    SUCCESS = 'success',
    ERROR = 'error'
}

export interface ContactFormFields {
    name: HTMLInputElement,
    email: HTMLInputElement,
    phone: HTMLInputElement,
    message: HTMLTextAreaElement,
    privacyPolicy: HTMLInputElement
}

export default class ContactFormComponent extends Component {

    static readonly MAIL_ENDPOINT = '/server/sendmail.php';

    static readonly ELEMENT_SUCCESS_MODIFIER = 'c-contact-form--success';
    static readonly ELEMENT_ERROR_MODIFIER = 'c-contact-form--error';

    protected readonly _element: HTMLFormElement;

    private readonly _info: SlideableHTMLElement;
    private readonly _formFields: ContactFormFields;
    private readonly _submit: HTMLElement;

    private _status: ContactFormStatus = ContactFormStatus.INITIAL;
    private _messageSent: boolean = false;

    constructor(element: HTMLFormElement) {
        super(element);

        this._info = element.querySelector('.c-contact-form__info') as SlideableHTMLElement;

        this._formFields = {
            name: element.querySelector('#contact-form-input-name'),
            email: element.querySelector('#contact-form-input-email'),
            phone: element.querySelector('#contact-form-input-phone'),
            message: element.querySelector('#contact-form-input-message'),
            privacyPolicy: element.querySelector('#contact-form-input-privacy-policy'),
        };

        this._submit = element.querySelector('.c-contact-form__submit');

        this._hideInfoMessage();
        this._initListeners();
    }

    get status(): ContactFormStatus {
        return this._status;
    }

    set status(status: ContactFormStatus) {
        console.log('ContactFormComponent', 'set status', status, this._status);

        if (status === this._status) {
            return;
        }

        this._status = status;

        switch (status) {

            case ContactFormStatus.INITIAL:
                this._element.classList.remove(ContactFormComponent.ELEMENT_SUCCESS_MODIFIER, ContactFormComponent.ELEMENT_ERROR_MODIFIER);
                break;

            case ContactFormStatus.SUCCESS:
                this._element.classList.remove(ContactFormComponent.ELEMENT_ERROR_MODIFIER);
                this._element.classList.add(ContactFormComponent.ELEMENT_SUCCESS_MODIFIER);
                break;

            case ContactFormStatus.ERROR:
                this._element.classList.remove(ContactFormComponent.ELEMENT_SUCCESS_MODIFIER);
                this._element.classList.add(ContactFormComponent.ELEMENT_ERROR_MODIFIER);
                break;

        }
    }

    private _initListeners() {
        this._element.addEventListener('submit', this._onSubmit.bind(this));
    }

    private _onSubmit(e: Event) {

        e.preventDefault();

        if (this._messageSent) {
            return false;
        }

        this.status = ContactFormStatus.INITIAL;
        this._hideInfoMessage().then(() => {

            const xhr = new XMLHttpRequest();

            xhr.open('POST', ContactFormComponent.MAIL_ENDPOINT);

            xhr.setRequestHeader('Content-Type', 'application/x-www-form-urlencoded');

            xhr.addEventListener('load', () => {
                console.log('ContactFormComponent', '_onSubmit', 'onXHRLoad', xhr.readyState, xhr.status, xhr, e);

                switch (xhr.status) {

                    case 200:   // success
                        this.status = ContactFormStatus.SUCCESS;
                        this._showInfoMessage(`<i class="fas fa-check-circle"></i> <span>Vielen Dank für Ihre Nachricht!</span>`);
                        this._disableFormControls();
                        break;

                    case 400:   // bad request - field(s) missing
                        this.status = ContactFormStatus.ERROR;
                        this._showInfoMessage(`<i class="fas fa-exclamation-triangle"></i> <span>Ihre Nachricht konnte nicht versendet werden. Bitte prüfen Sie Ihre Angaben und versuchen Sie es erneut.</span>`);
                        break;

                    case 403:   // forbidden - form already sent
                        this.status = ContactFormStatus.ERROR;
                        this._showInfoMessage(`<i class="fas fa-exclamation-triangle"></i> <span>Sie haben bereits eine Nachricht gesendet.</span>`);
                        this._disableFormControls();
                        break;

                    case 406:   // not acceptable - field(s) not valid
                        this.status = ContactFormStatus.ERROR;
                        this._showInfoMessage(`<i class="fas fa-exclamation-triangle"></i> <span>Ihre Nachricht konnte nicht versendet werden. Bitte prüfen Sie Ihre Angaben und versuchen Sie es erneut.</span>`);
                        break;

                }

            });

            const serializedForm = GeneralUtils.serializeForm(this._element);

            xhr.send(serializedForm);

        });

    }

    private _showInfoMessage(message: string): Promise<any> {
        console.log('ContactFormComponent', '_showInfoMessage', message);
        this._info.innerHTML = message;
        return this._info.slideDown();
    }

    private _hideInfoMessage(): Promise<any> {
        console.log('ContactFormComponent', '_hideInfoMessage');
        return this._info.slideUp();
    }

    private _disableFormControls() {

        for (const key of Object.keys(this._formFields)) {
            // @ts-ignore
            if (this._formFields.hasOwnProperty(key) && this._formFields[key]) {
                // @ts-ignore
                const formField: HTMLInputElement | HTMLTextAreaElement = this._formFields[key];

                //formField.value = '';
                formField.setAttribute('disabled', 'disabled');

            }
        }

        this._submit.setAttribute('disabled', 'disabled');

    }
}
