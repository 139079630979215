import Component, { IAbstractComponentInstance } from './component';

export default abstract class Controller<T extends Component = Component> {

    private readonly _elements: NodeListOf<HTMLElement>;
    private readonly _componentClass: IAbstractComponentInstance<T>;
    private _components: T[] = [];

    constructor(elements: NodeListOf<HTMLElement>, componentClass: IAbstractComponentInstance<T>) {
        this._elements = elements;
        this._componentClass = componentClass;

        this._initComponents();
    }

    private _initComponents() {

        // @ts-ignore
        this._elements.forEach(element => {
            this._components.push(new this._componentClass(element));
        });

    }

}
