import Component from '../../component';
import { GeneralUtils } from '../../utils/general.utils';

const GOOGLE_MAPS_API_KEY = 'AIzaSyCUNnTfDe5MQNicTz5c8XZiC_LkX6W6syA';
const GOOGLE_MAPS_URL = `https://maps.googleapis.com/maps/api/js?key=${GOOGLE_MAPS_API_KEY}`;

export default class ContactMapComponent extends Component {

    static readonly MARKER_ICON = 'images/contact-map/marker.png';
    static readonly MARKER_ICON_MOBILE = 'images/contact-map/marker_mobile.png';

    // for 'Straubinger Str. 77, 93055 Regensburg'
    private readonly _markerLat: number = 49.013952; //49.014315;
    private readonly _markerLng: number = 12.143668; //12.144024;

    private readonly _centerLat: number = 49.018330; //49.018693;
    private readonly _centerLng: number = 12.144003; //12.144359;
    private readonly _centerLatMobile: number = 49.021531; //49.021894;
    private readonly _centerLngMobile: number = 12.143955; //12.144311;

    private readonly _zoom: number = 14;
    private readonly _styles: google.maps.MapTypeStyle[] = [
        {
            featureType: 'all',
            elementType: 'all',
            stylers: [
                {
                    'saturation': -100
                }
            ]
        }
    ];

    private readonly _mapElement: HTMLElement;

    private _wasMobile: boolean;
    private _isMobile: boolean;

    private _center: google.maps.LatLng;
    private _map: google.maps.Map;
    private _markerPosition: google.maps.LatLng;
    private _marker: google.maps.Marker;

    constructor(element: HTMLElement) {
        super(element);

        this._mapElement = this._element.querySelector('.c-contact-map__map');

        this._isMobile = window.innerWidth < GeneralUtils.MOBILE_BREAKPOINT;
        this._wasMobile = this._isMobile;

        this._detectMobile();
        this._initListeners();
        this._initIntersectionObserver();
    }

    private _detectMobile() {
        this._wasMobile = this._isMobile;
        this._isMobile = window.innerWidth < GeneralUtils.MOBILE_BREAKPOINT;
    }

    private _initListeners() {
        window.addEventListener('resize', this._onWindowResize.bind(this), { passive: true });
    }

    private _onWindowResize(e: any) {
        this._detectMobile();

        if ((this._isMobile && !this._wasMobile) || (!this._isMobile && this._wasMobile)) {
            this._updateMap();
        }
    }

    private _updateMap() {

        if (!this._map) {
            return;
        }

        if (this._isMobile) {
            this._center = new google.maps.LatLng(this._centerLatMobile, this._centerLngMobile);
            this._map.setCenter(this._center);
            this._marker.setIcon(ContactMapComponent.MARKER_ICON_MOBILE);
        } else {
            this._center = new google.maps.LatLng(this._centerLat, this._centerLng);
            this._map.setCenter(this._center);
            this._marker.setIcon(ContactMapComponent.MARKER_ICON);
        }

    }

    private _initIntersectionObserver() {

        const observerOptions = {
            rootMargin: '100px',
            threshold: 0
        };

        const observer = new IntersectionObserver(this._onMapIntersection.bind(this), observerOptions);
        observer.observe(this._mapElement);

    }

    private _onMapIntersection(entries: IntersectionObserverEntry[], observer: IntersectionObserver) {

        // Intersecting with Edge workaround https://calendar.perfplanet.com/2017/progressive-image-loading-using-intersection-observer-and-sqip/#comment-102838
        const isIntersecting = typeof entries[0].isIntersecting === 'boolean' ? entries[0].isIntersecting : entries[0].intersectionRatio > 0;

        if (isIntersecting) {
            this._loadGoogleMaps();
            observer.unobserve(this._mapElement);
        }

    }

    private _loadGoogleMaps() {
        console.log('ContactMapComponent', '_loadGoogleMaps');

        if (!document.querySelectorAll(`[src="${GOOGLE_MAPS_URL}"]`).length) {
            // @ts-ignore
            document.body.appendChild(Object.assign(
                document.createElement('script'), {
                    type: 'text/javascript',
                    src: GOOGLE_MAPS_URL,
                    onload: this._initMap.bind(this)
                }));
        } else {
            this._initMap();
        }

    }

    private _initMap() {
        console.log('ContactMapComponent', '_initMap', this._mapElement);

        if (!this._mapElement) {
            return;
    }

        this._center = new google.maps.LatLng(this._centerLat, this._centerLng);

        this._map = new google.maps.Map(this._mapElement, {
            zoom: this._zoom,
            styles: this._styles,

            backgroundColor: '#dee2e6',
            fullscreenControl: false,
            gestureHandling: 'cooperative',
            mapTypeControl: false
        });

        this._markerPosition = new google.maps.LatLng(this._markerLat, this._markerLng);

        this._marker = new google.maps.Marker({
            position: this._markerPosition,
            map: this._map
        });

        this._updateMap();

    }

}
